import { Theme } from '@mui/material';
import { Moment } from 'moment';
import { Dispatch, ReactNode, SetStateAction } from 'react';
import { ToastOptions } from 'react-toastify';

import { LogoutReasons } from '@/constants/authConstants';
import { Roles } from '@/constants/roles';
import { MainEquipmentFormValues } from '@/forms/EquipmentForm/MainForm/types';
import { MeetingRoomsCollision, WorkplaceInfo } from '@/queries/booking/types';
import { ThemeMode } from '@/theme';
import type { Configuration, OfficeType } from '@/types/Configuration';
import type { UserInfo } from '@/types/UserInfo';

export interface ConfigurationContextValues extends Configuration {
	isLoading: boolean;
}

export interface ColorModeContextValues {
	mode: ThemeMode;
	toggleThemeMode: () => void;
	theme: Theme;
}

export interface ToastContextValues {
	open: (params: {
		message: string | JSX.Element | null;
		settings: ToastOptions;
	}) => void;
	subscribe: () => void;
}

export interface CalendarHighlightedDays {
	bookedList: number[];
	yourList: number[];
	blockedList: number[];
}

export interface CalendarCollisionsDays {
	collisionList: number[];
}

export interface HighlightedDays {
	bookedList: Moment[];
	yourList: Moment[];
	blockStartDate: Moment | null;
}

interface Workplace {
	department: string;
	firstName: string;
	isBlocked: boolean;
	lastName: string;
	userId: number;
	workplaceId: number;
	workplaceNumber: number;
}

export type Employee = {
	id: number;
	name: string;
	positionDescription: string;
};

export enum SelectedBookingItemType {
	Desk,
	Room,
}

export interface SelectedDesk {
	id: number;
	displayNumber: number;
	spaceId: number | null;
	abbreviation: string;
	type: SelectedBookingItemType.Desk;
}

export interface SelectedRoom {
	id: number;
	name: string;
	type: SelectedBookingItemType.Room;
}

export type SelectedBookingItem = SelectedDesk | SelectedRoom;

export interface SelectedTime {
	from: Moment;
	to: Moment;
}

export interface BookingContextInitialValues {
	timelineDate: Moment;
	isAllDayChecked: boolean;
	selectedSpaceId: number | null;
	isWorkingHours: boolean;
	selectedItem: SelectedBookingItem | null;
	selectedTime: SelectedTime | null;
	firstDay: Moment | null;
	lastDay: Moment | null;
	employee: Employee | null;
	office: OfficeType;
	initialOffice: OfficeType | null;
	initialEmployee: Employee;
	highlightedDays: HighlightedDays;
	workplaces: Workplace[];
	error: null | string;
	selectedWorkplace?: Workplace;
	weeksCount: number;
	hasWorkplaceCollision: boolean | undefined;
	meetingRoomCollisions: Array<MeetingRoomsCollision>;
	officeWorkplaces: Array<WorkplaceInfo>;
	isTimelineDateSynchronized: boolean;
}

export interface BookingContextValues extends BookingContextInitialValues {
	setTimelineDate: Dispatch<SetStateAction<Moment>>;
	setIsAllDayChecked: Dispatch<SetStateAction<boolean>>;
	setIsWorkingHours: Dispatch<SetStateAction<boolean>>;
	setSelectedSpaceId: (spaceId: number | null) => void;
	setSelectedItem: (selectedItem: SelectedBookingItem | null) => void;
	setSelectedTime: (time: SelectedTime | null) => void;
	setFirstDay: (day: Moment | null) => void;
	setLastDay: (day: Moment | null) => void;
	setEmployee: Dispatch<SetStateAction<Employee | null>>;
	setOffice: Dispatch<SetStateAction<OfficeType>>;
	setHighlightedDays: (days: HighlightedDays) => void;
	setWorkplaces: Dispatch<SetStateAction<Workplace[]>>;
	setWeeksCount: Dispatch<SetStateAction<number>>;
	setHasWorkplaceCollision: Dispatch<SetStateAction<boolean | undefined>>;
	setError: Dispatch<SetStateAction<string | null>>;
	init: () => void;
	resetOnMeetingTabChange: () => void;
	setMeetingRoomCollisions: Dispatch<
		SetStateAction<Array<MeetingRoomsCollision>>
	>;
	setInitialTimelineDate: Dispatch<SetStateAction<Moment>>;
	setOfficeWorkplaces: Dispatch<SetStateAction<Array<WorkplaceInfo>>>;

	areMeetingRoomDisabled: boolean;
	areMeetingRoomsSelected: boolean;
	isTimelineInRange: boolean;
	isBlockedDesk: boolean;
	isSelectedRoomAvailable: boolean;
	userHasRoomBookingsForTimelinePeriod: boolean;
	isSelectedMeetingRoomTimeInPast: boolean;
}

export interface BookingProviderProps {
	children: ReactNode;
	initialBookingContextState: BookingContextInitialValues;
}

export type Payload =
	| number
	| []
	| null
	| OfficeType
	| SelectedBookingItem
	| Array<MeetingRoomsCollision>
	| boolean;

export interface Action {
	type: string;
	payload: Payload;
}

export interface EquipmentContextValues {
	equipmentModel: MainEquipmentFormValues;
	setEquipmentModel: Dispatch<SetStateAction<MainEquipmentFormValues>>;
}

export type AuthState = {
	token: string | null;
	role: Roles;
	loading: boolean;
	handleReceivedToken?: HandleReceivedToken;
	onLogout?: (reason?: LogoutReasons) => void;
} & Partial<UserInfo>;

export type InitialAuthState = Omit<
	AuthState,
	'handleReceivedToken' | 'onLogout' | 'loading'
>;

export type HandleReceivedToken = (token: string) => void;

export enum SessionBroadcastEvents {
	requestSession = 'requestSession',
	sendSession = 'sendSession',
}

export type SessionBroadcastMessage = {
	type: string;
	data?: string;
};

export interface BookingMapContextValues {
	isMapOpen: boolean;
	setIsMapOpen: Dispatch<SetStateAction<boolean>>;
	isLoading: boolean;
	setIsLoading: Dispatch<SetStateAction<boolean>>;
}
