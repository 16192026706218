import { Block, PopUp, Typography, Warning } from '@mms/mms-ui-library';
import { Moment } from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { useIsFetching } from 'react-query';

import { PopUpBody } from '../../../PopUps/PopUpBody';
import { SelectedItemView } from '../SelectedSeatView';

import { ProtectedView } from '@/components/ProtectedView';
import {
	REPEAT_WEEKS_MAX_VALUE,
	REPEAT_WEEKS_MIN_VALUE,
	Directions,
	Roles,
	SURE_TO_BLOCK_DESK,
	BLOCK_DIALOG_HEADER,
	chiefRoles,
	BookingQueriesKeys,
} from '@/constants/index';
import { useBookingContext } from '@/context/Booking';
import { useAuth } from '@/context/index';
import { SelectedBookingItemType } from '@/context/types';
import { getDeskBlockDialogSubtext, getTooltipTitle } from '@/helpers/booking';
import { getDeskName } from '@/pages/Booking/utils';

import { AllDaySwitch } from './AllDaySwitch';
import { BookButton } from './BookButton';
import { BOOKING_POPUP_STYLES_CONFIG } from './constants';
import { useMutateBookingItem } from './hooks/useMutateBookingItem';
import { Repetitive } from './Repetitive';
import {
	ButtonsWrapper,
	WarningPopupContainer,
	Wrapper,
	SwitchContainer,
} from './styles';
import { getBookingItemName } from './utils';

const { CEO, CTO } = Roles;
const BOOK_BUTTON_ROLES = [CEO, CTO];

export function BookingControls() {
	const [isDialogOpen, setIsDialogOpen] = useState(false);

	const [isChecked, setIsChecked] = useState(false);
	const [warning, setWarning] = useState<null | string>(null);
	const { role } = useAuth();

	const areCollisionsLoading = useIsFetching(BookingQueriesKeys.collisions);

	const {
		selectedItem,
		areMeetingRoomsSelected,
		firstDay,
		lastDay,
		office,
		setWeeksCount,
		weeksCount,
		isAllDayChecked,
		setIsAllDayChecked,
		hasWorkplaceCollision,
		areMeetingRoomDisabled,
		isBlockedDesk,
		userHasRoomBookingsForTimelinePeriod,
		isSelectedMeetingRoomTimeInPast,
	} = useBookingContext();

	useEffect(() => {
		setIsChecked(false);
	}, [office]);

	useEffect(() => {
		setWeeksCount(isChecked ? 1 : 0);
	}, [isChecked]);

	useEffect(() => {
		if (!areMeetingRoomsSelected) {
			setIsChecked(false);
		}
	}, [firstDay, areMeetingRoomsSelected]);

	const handleChange = useCallback(() => {
		setWeeksCount(isChecked ? 1 : 0);
		setIsChecked(!isChecked);
	}, [isChecked]);

	const handleAllDayChange = useCallback(() => {
		setIsAllDayChecked(!isAllDayChecked);
	}, [isAllDayChecked]);

	const handleWeeksChange = useCallback(
		(direction: string) => () => {
			const isUp = direction === Directions.Up;
			const newValue = isUp ? weeksCount + 1 : weeksCount - 1;

			if (
				(isUp && newValue <= REPEAT_WEEKS_MAX_VALUE) ||
				(!isUp && newValue >= REPEAT_WEEKS_MIN_VALUE)
			) {
				setWeeksCount(newValue);
			}
		},
		[weeksCount]
	);

	const areButtonsDisabled = selectedItem === null || !firstDay || !lastDay;

	const handleClose = () => setWarning(null);

	const initiateDeskBlock = useCallback(() => {
		if (areButtonsDisabled) {
			return;
		}
		setIsDialogOpen(true);
	}, [areButtonsDisabled]);

	const handleCloseDialog = useCallback(() => {
		setIsDialogOpen(false);
	}, []);

	const { handleBookClick, handleBlock } = useMutateBookingItem(
		isChecked,
		setIsChecked,
		setIsDialogOpen,
		setWarning
	);

	const isSelectedDateSingle = firstDay?.isSame(lastDay, 'day') || false;

	const isBookButtonDisabled =
		(hasWorkplaceCollision === undefined && Boolean(areCollisionsLoading)) ||
		areMeetingRoomDisabled ||
		(!areMeetingRoomsSelected && hasWorkplaceCollision) ||
		(areMeetingRoomsSelected &&
			(isSelectedMeetingRoomTimeInPast ||
				userHasRoomBookingsForTimelinePeriod)) ||
		areButtonsDisabled;

	const isBlockDisabled =
		isBlockedDesk || areButtonsDisabled || !isSelectedDateSingle;

	return (
		<>
			<Wrapper>
				<div>
					<SwitchContainer isTwoRows={isChecked}>
						<Repetitive
							isChecked={isChecked}
							isControlDisabled={!isSelectedDateSingle}
							weeksCount={weeksCount}
							handleWeeksChange={handleWeeksChange}
							handleChange={handleChange}
						/>
						{areMeetingRoomsSelected && (
							<ProtectedView roles={chiefRoles}>
								<AllDaySwitch
									checked={isAllDayChecked}
									isDisabled={false}
									handleChange={handleAllDayChange}
								/>
							</ProtectedView>
						)}
					</SwitchContainer>
					{selectedItem && <SelectedItemView item={selectedItem} />}
				</div>
				<ButtonsWrapper isChiefRole={chiefRoles.includes(role)}>
					<BookButton
						disabled={isBookButtonDisabled}
						tooltipTitle={
							getTooltipTitle(
								lastDay as Moment,
								selectedItem,
								areMeetingRoomsSelected
							) || ''
						}
						onClick={handleBookClick}
						color="accent"
					>
						Book a {getBookingItemName(areMeetingRoomsSelected)}
					</BookButton>
					{!areMeetingRoomsSelected && (
						<ProtectedView roles={BOOK_BUTTON_ROLES}>
							<BookButton
								disabled={isBlockDisabled}
								color="primary"
								tooltipTitle={
									getTooltipTitle(
										lastDay as Moment,
										selectedItem,
										areMeetingRoomsSelected
									) || ''
								}
								onClick={initiateDeskBlock}
							>
								Block a desk
							</BookButton>
						</ProtectedView>
					)}
				</ButtonsWrapper>
			</Wrapper>
			{warning && (
				<PopUp
					title="WARNING"
					headerIcon={<Warning />}
					onClose={handleClose}
					stylesConfig={BOOKING_POPUP_STYLES_CONFIG}
					type="approve"
					headerType="warning"
					showCloseButton
				>
					<WarningPopupContainer>
						<Typography variant="m-400">{warning}</Typography>
					</WarningPopupContainer>
				</PopUp>
			)}
			{!areButtonsDisabled &&
				isDialogOpen &&
				selectedItem.type === SelectedBookingItemType.Desk && (
					<PopUp
						title={BLOCK_DIALOG_HEADER}
						headerIcon={<Block />}
						onClose={handleCloseDialog}
						type="approve"
						showCloseButton={false}
						controls={{
							negativeControl: { onClick: handleCloseDialog },
							positiveControl: { onClick: handleBlock },
						}}
					>
						<PopUpBody>
							<Typography variant="m-400">{SURE_TO_BLOCK_DESK}</Typography>
							<Typography variant="m-600">
								{getDeskName(
									selectedItem.abbreviation,
									selectedItem.displayNumber
								)}
							</Typography>
							<Typography variant="m-600">
								{getDeskBlockDialogSubtext(firstDay)}
							</Typography>
						</PopUpBody>
					</PopUp>
				)}
		</>
	);
}
