export const Keys = {
	Backspace: 'Backspace',
	Delete: 'Delete',
};

export const BookingQueriesKeys = {
	notifications: 'notifications',
	bookings: 'bookings',
	meetingRoomsBookings: 'meeting-rooms-bookings',
	reservations: 'reservations',
	meetingRoomReservations: 'meeting-room-reservations',
	collisions: 'collisions',
	meetingRoomCollisions: 'meeting-room-collisions',
	meetingRooms: 'meeting-rooms',
	spaceWorkplaces: 'space-workplaces',
};

export const ClientQuerieKeys = {
	clients: 'clients',
	clientsLookup: 'clients-lookup',
	clientsProjects: 'clients-projects',
	clientProjectsForForm: 'clients-projects-for-form',
};

export const ReportsQueriesKyes = {
	reports: 'reports',
};

export const ProjectsQueriesKeys = {
	projects: 'projects',
	projectsByClientLookup: 'projects-by-client-lookup',
};

export const DashboardQueriesKeys = {
	dashboardManager: 'dashboard-manager',
	dashboardManagerTimeStack: 'dashboard-manager-time-stack',
	dashboardManagerUsers: 'dashboard-manager-users',
	dashboardManagerUser: 'dashboard-manager-user',
	dashboardManagerUserProject: 'dashboard-manager-user-project',
	dashboardManagerPieChart: 'dashboard-manager-pie-chart',
	dashboardOverview: 'dashboard-overview',
	dashboard: 'dashboard',
	dashboardBarChart: 'dashboard-bar-chart-data',
};

export const UsersQueriesKeys = {
	users: 'users',
	chiefs: 'chief-users',
	allUsers: 'users-all-lookup',
	email: 'user-email',
};

export const TeamboardQueriesKeys = {
	teamboard: 'teamboard',
	teamboardUsersLookup: 'teamboard-users-lookup',
	employmentHistory: 'employmentHistory',
	personalData: 'personalData',
	comment: 'comment',
	userHardSkills: 'userHardSkills',
	userSoftSkill: 'user-soft-skills',
};

export const EquipmentQueriesKeys = {
	file: 'equipment-document',
	equipments: 'equipments',
	user: 'user-equipments',
	info: 'user-info',
	editing: 'equipment-editing-info',
	history: 'equipment-history',
	deleted: 'equipment-deleted-history',
};

export const SignatureQueriesKeys = {
	signature: 'signature',
	file: 'file',
};

export const AuthQueryKeys = {
	redirect: 'login-redirect',
};

export const NotificationQueriesKeys = {
	notificationPreview: 'notifications-preview',
	userNotifications: 'user-notifications',
	reportFile: 'user-notifications',
};

export const AdminPanelSkillsQueriesKeys = {
	specializations: 'specializations',
	hardSkills: 'hard-skills',
};
