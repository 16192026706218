import { useMemo } from 'react';

import { MINSK_SPACES, WARSAW_SPACE } from '../constants';
import { OfficeWorkplaceConfiguration, OfficeWorkspaceType } from '../types';

import { FeatureName } from '@/constants/featureNames';
import { useFeatureFlag } from '@/hooks/useFeatureFlag';
import { areMeetingRoomsOpen } from '@/pages/Booking/utils';
import { WorkplaceInfo } from '@/queries/booking/types';
import { LocationTypeId, OfficeType } from '@/types/Configuration';

const NO_SPACES_INDEX = 0;

interface UseWorkplaceConfigurationOptions {
	office: OfficeType;
	spaceId: number | null;
	officeWorkplaces: WorkplaceInfo[];
}

interface WorkplaceConfiguration {
	configuration: OfficeWorkplaceConfiguration | null;
	isAvailable: boolean;
}

export const useWorkplaceConfiguration = ({
	office,
	spaceId,
	officeWorkplaces,
}: UseWorkplaceConfigurationOptions): WorkplaceConfiguration => {
	const isMinskOfficeAvailable = useFeatureFlag(
		FeatureName.MinskOfficeAvailable
	);

	const spaceWorkplacesMap = useMemo(() => {
		if (!officeWorkplaces) {
			return null;
		}

		return officeWorkplaces.reduce<Record<number, WorkplaceInfo[]>>(
			(acc, workplace) => {
				const { workspaceId } = workplace;

				const workspacesId = workspaceId || NO_SPACES_INDEX;

				if (!acc[workspacesId]) {
					acc[workspacesId] = [];
				}

				acc[workspacesId].push(workplace);

				return acc;
			},
			{}
		);
	}, [officeWorkplaces]);

	const workspaceConfiguration: WorkplaceConfiguration = useMemo(() => {
		if (areMeetingRoomsOpen(spaceId)) {
			return {
				configuration: {
					type: OfficeWorkspaceType.MeetingRooms,
				},
				isAvailable: true,
			};
		}

		if (
			office.location === LocationTypeId.Minsk &&
			spaceId &&
			spaceWorkplacesMap &&
			spaceWorkplacesMap[spaceId] &&
			MINSK_SPACES[spaceId]
		) {
			return {
				configuration: MINSK_SPACES[spaceId](spaceWorkplacesMap[spaceId]),
				isAvailable: isMinskOfficeAvailable,
			};
		}

		if (
			office.location === LocationTypeId.Warsaw &&
			spaceWorkplacesMap &&
			spaceWorkplacesMap[NO_SPACES_INDEX]
		) {
			return {
				configuration: WARSAW_SPACE(spaceWorkplacesMap[NO_SPACES_INDEX]),
				isAvailable: true,
			};
		}

		return {
			configuration: null,
			isAvailable: true,
		};
	}, [office, isMinskOfficeAvailable, spaceId, spaceWorkplacesMap]);

	return workspaceConfiguration;
};
